import { createSlice } from '@reduxjs/toolkit'
import { API_ACTIVE_INACTIVE, API_ADD_USER, API_DELETE_GOAT, API_GET_USER_DETAIL, API_GET_USER_LIST, API_INVITE_GUIDE, API_USER_ANALYTICS, API_USER_DELETE, API_USER_EDIT, API_USER_GOAT_ANALYTICS, GET_SUBSCRIPTION_HISTORY, GET_USER_GOATS } from '../constants/index'


// const projectName = "GOAT-Grub";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    userDetail: {},
    sidebarShow: true,
    userList: { data: [], recordsTotal: 0 },
    subscriptionHistoryList: { data: [], recordsTotal: 0 },
    getUserGoatsList: { data: [], recordsTotal: 0 },
    userAnalytics: {},
    userGoatAnalytics: {},
    guideInvite: {},
    skip: 0,
    limit: 10,
}

//Get User List Action
export const getUserList = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_GET_USER_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUserList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserList/fail',
            payload: {},
        }),
    },
})

export const addUser = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_ADD_USER,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addUser/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addUser/fail',
            payload: {},
        }),
    },
})
//Get User Detail Action
export const getUserDetail = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_GET_USER_DETAIL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUserDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserDetail/fail',
            payload: {},
        }),
    },
})

//User Edit Action
export const userEditAction = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_USER_EDIT,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'userEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'userEdit/fail',
            payload: {},
        }),
    },
})

//User Delete Action
export const userDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_USER_DELETE,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteUser/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteUser/fail',
            payload: {},
        }),
    }
})

export const userActiveInactiveAction = (data) => ({
    type: 'API',
    payload: {
        url: API_ACTIVE_INACTIVE,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'activeInactive/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'activeInactive/fail',
            payload: {},
        }),
    }
})

//subscriptionHistory
export const getSubscriptionHistoryList = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: GET_SUBSCRIPTION_HISTORY,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getSubscriptionList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getSubscriptionList/fail',
            payload: {},
        }),
    },
})


//
export const getUserGoatsList = (data) => ({
    type: 'API',
    payload: {
        url: GET_USER_GOATS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUserGoatList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUserGoatList/fail',
            payload: {},
        }),
    },
})

//GOAT Delete Action
export const goatDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_GOAT,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteGoat/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteGoat/fail',
            payload: {},
        }),
    }
})

// user analytics
export const getUserAnalytics = (data) => ({
    type: 'API',
    payload: {
        url: API_USER_ANALYTICS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'userAnalytics/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'userAnalytics/fail',
            payload: {},
        }),
    },
})

export const getUserGoatAnalytics = (data) => ({
    type: 'API',
    payload: {
        url: API_USER_GOAT_ANALYTICS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'userGoatAnalytics/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'userGoatAnalytics/fail',
            payload: {},
        }),
    },
})
export const guideInvite = (data) => ({
    type: 'API',
    payload: {
        url: API_INVITE_GUIDE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'guideInvite/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'guideInvite/fail',
            payload: {},
        }),
    },
})
// Reducer
const getUserSlice = createSlice({
    name: 'getUserList',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getUserList/success', (state, action) => {
            state.userList = action.payload.data
        })
        builder.addCase('getUserList/fail', (state, action) => {
            state.userList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('addUser/success', (state, action) => {
            // console.log('action: ', action);
            state.userList = action.payload.data
        })
        builder.addCase('addUser/fail', (state, action) => {
            state.userList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getUserDetail/success', (state, action) => {
            state.userDetail = action.payload.data
        })
        builder.addCase('getUserDetail/fail', (state, action) => {
            state.userDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('userEdit/success', (state, action) => {
            state.userDetail = action.payload.data
        })
        builder.addCase('userEdit/fail', (state, action) => {
            state.userDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('getSubscriptionList/success', (state, action) => {
            state.subscriptionHistoryList = action.payload.data
        })
        builder.addCase('getSubscriptionList/fail', (state, action) => {
            state.subscriptionHistoryList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getUserGoatList/success', (state, action) => {
            state.getUserGoatsList = action.payload.data
        })
        builder.addCase('getUserGoatList/fail', (state, action) => {
            state.getUserGoatsList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('userAnalytics/success', (state, action) => {
            state.userAnalytics = action.payload.data
        })
        builder.addCase('userAnalytics/fail', (state, action) => {
            state.userAnalytics = {}
            state.isLoggedIn = false
        })
        builder.addCase('userGoatAnalytics/success', (state, action) => {
            state.userGoatAnalytics = action.payload.data
        })
        builder.addCase('userGoatAnalytics/fail', (state, action) => {
            state.userGoatAnalytics = {}
            state.isLoggedIn = false
        })
        builder.addCase('guideInvite/success', (state, action) => {
            // console.log('action: ', action);
            state.guideInvite = action.payload.data
        })
        builder.addCase('guideInvite/fail', (state, action) => {
            state.guideInvite = {}
            state.isLoggedIn = false
        })

    },
})

export const { loaderChange } = getUserSlice.actions
export default getUserSlice.reducer
