import { createGlobalStyle } from "styled-components";
import { device } from './../breakpoints';
import { toAbsoluteUrl } from "../../utils";

export const CommonTable = createGlobalStyle`

    .ant-table-wrapper {
        table{
          /* border: 1px solid #f0f0f0; */
          border-radius: 0;
        }
        .ant-table-thead{
            tr{
                th{
                    background: #f0f3f5;
                    color: #000 !important;
                    border-radius: 0 !important;
                    /* border-right: 1px solid #f0f0f0; */
                    &:before{
                      display: none;
                    }
                }
            }
        }
        tbody{
          tr{
              td{
                color: #000;
                .green,.yellow,.orange,.red,.blue{
                    border-radius: 8px;
                    padding: 1px 12px;
                    max-width: max-content;
                    color: #fff;
                }
                .green{
                    background-color: #00FF00;
                }
                .yellow {
                    background-color: #FFFF00;
                    color: #000;
                }
                .orange {
                    background-color: #FFA500;
                }
                .red {
                    background-color: #FF0000;
                }
                .blue {
                    background-color: #0000FF;
                }
              }
              /* td{
                border-right: 1px solid #f0f0f0;
                &:last-child{
                  border-right: none;
                }
              }
              &:last-child{
                td{
                  border-bottom: none;
                }
              } */
          }
        }
        .image{
            width: 70px;
            height: 70px;
            img{
                width:100%;
                height: 100%;
                object-fit:cover;
                border-radius:100%;
            }
        }
        .ant-space {
            .ant-btn{
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                box-shadow: none;
                border-radius:100%;
                &.view-btn{
                    color: #ffffff !important;
                    background-color: #2f353a;
                    border-color: #2f353a;
                }
                &.edit-btn{
                    color: #23282c !important;
                    background-color: #c8ced3;
                    border-color: #c8ced3;
                }
                &.delete-btn{
                    color: #ffffff !important;
                    background-color: #f86c6b;
                    border-color: #f86c6b;
                }
                &.accept-btn{
                    color: #ffffff !important;
                    background-color: green;
                    border-color: #2f353a;
                }
                &.reject-btn{
                    color: #ffffff !important; 
                    background-color: #a71818;
                    border-color: #2f353a;
                }
            }
        }

        .ant-spin-dot-item {
            background-color: #f6883d;
        }
        
    }
    .custom-pagination{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top:15px;
        .ant-pagination{
            display: flex;
            align-items: center;
            li{
                font-family: 'SF Pro Display';
                font-weight: 400;
                font-size: 12px;
                color: #242424;
                min-width:auto;
                margin:0;
                &:nth-child(1){
                    order:2;
                    margin: 0 15px;
                    /* margin: 0 35px;
                    @media only screen and (${device.sm}){
                        margin: 0 15px;
                    } */
                }
                &:nth-child(2){
                    order:1;
                }
                &:nth-child(3){
                    order:3;
                }
                &.ant-pagination-simple-pager{
                    margin: 0;
                    display: flex;
                    align-items: center;
                    input{
                        width: 28px;
                        padding: 0;
                        border: none;
                        margin: 0;
                        font-weight: 400;
                        font-size: 12px;
                        color: #242424;
                        &:focus{
                            box-shadow:none;
                        }
                    }
                    .ant-pagination-slash{
                        margin-inline-end:4px;
                        margin-inline-start: 0;
                        font-size:0;
                        &:before{
                            content: "of";
                            font-size:12px;
                        }
                    }
                }
            }
            &.pagination-none{
                margin-right:10px;
                /* margin-right:30px;
                @media only screen and (${device.sm}){
                    margin-right:10px ;
                } */
                li{
                    display: none;
                    &.ant-pagination-options{
                        display: flex;
                        align-items: center;
                        margin: 0;
                        &:before{
                            font-family: 'SF Pro Display';
                            font-weight: 400;
                            font-size: 12px;
                            color: #6B7280;
                            content: "Rows per page:";
                            margin-right:8px;
                            @media only screen and (${device.sm}){
                                margin-right:5px;
                            }
                        }
                        .ant-select{
                            .ant-select-selector{
                                height: auto;
                                padding: 0;
                                border: none;
                                box-shadow:none;
                                .ant-select-selection-search{
                                    display: none;
                                }
                                .ant-select-selection-item{
                                    font-family: 'SF Pro Display';
                                    font-weight: 400;
                                    font-size: 12px;
                                    letter-spacing: 0.3px;
                                    color: #242424;
                                    &:after{
                                        position: absolute;
                                        content: "";
                                        visibility: visible;
                                        background-image: url(${toAbsoluteUrl(`/images/pagination-arrow.svg`)});
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto 0;
                                        width: 11px;
                                        height: 5px;
                                    }
                                }
                            }
                            .ant-select-arrow{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

`;