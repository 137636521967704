import { createSlice } from '@reduxjs/toolkit'
import { API_GET_ONBOARDINGSCREENS_LIST, API_ADD_ONBOARDINGSCREENS, API_EDIT_ONBOARDINGSCREENS, API_GET_ONBOARDINGSCREENS_DETAIL, API_DELETE_ONBOARDINGSCREENS } from '../constants/index'


// const projectName = "GOAT-Grub";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    onboardingscreensDetail: {},
    sidebarShow: true,
    onboardingscreensList: { data: [], recordsTotal: 0 },
    start: 0,
    length: 10,

}

//Get onboarding List Action
export const getOnboardingscreensList = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_GET_ONBOARDINGSCREENS_LIST,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getOnboardingscreensList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getOnboardingscreensList/fail',
            payload: {},
        }),
    },
})

//Add onboarding Action
export const addOnboardingscreens = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_ADD_ONBOARDINGSCREENS,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addOnboardingscreens/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addOnboardingscreens/fail',
            payload: {},
        }),
    },
})

//Get onboard Detail Action
export const getOnboardingsDetail = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_GET_ONBOARDINGSCREENS_DETAIL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getOnboardingscreensDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getOnboardingscreensDetail/fail',
            payload: {},
        }),
    },
})

//onboard Edit Action
export const onboardingscreensEditAction = (data) => ({
    type: 'API',
    payload: {
        // REAL
        // url: API_LOGIN,
        // DEMO
        url: API_EDIT_ONBOARDINGSCREENS,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'onboardingscreensEditAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'onboardingscreensEditAction/fail',
            payload: {},
        }),
    },
})

//onboarding Delete Action
export const onboardingscreensDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_ONBOARDINGSCREENS,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'onboardingscreensDeleteAction/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'onboardingscreensDeleteAction/fail',
            payload: {},
        }),
    }
})


// Reducer
const getOnboardingscreensSlice = createSlice({
    name: 'getOnboardingscreensList',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getOnboardingscreensList/success', (state, action) => {
            // console.log('action: ', action);
            state.onboardingscreensList = action.payload.data
        })
        builder.addCase('getOnboardingscreensList/fail', (state, action) => {
            state.onboardingscreensList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('addOnboardingscreens/success', (state, action) => {
            // console.log('action: ', action);
            state.onboardingscreensList = action.payload.data
        })
        builder.addCase('addOnboardingscreens/fail', (state, action) => {
            state.onboardingscreensList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getOnboardingscreensDetail/success', (state, action) => {
            // console.log('action: ', action);
            state.onboardingscreensDetail = action.payload.data
        })
        builder.addCase('getOnboardingscreensDetail/fail', (state, action) => {
            state.onboardingscreensDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('onboardingscreensEditAction/success', (state, action) => {
            // console.log('action: ', action);
            state.onboardingscreensDetail = action.payload.data
        })
        builder.addCase('onboardingscreensEditAction/fail', (state, action) => {
            state.onboardingscreensDetail = {}
            state.isLoggedIn = false
        })
    },
})

export const { loaderChange } = getOnboardingscreensSlice.actions
export default getOnboardingscreensSlice.reducer
