import { createSlice } from '@reduxjs/toolkit'
import { API_ADD_CREDIT, API_COMMUNICATION_APPROVE_REJECT, API_COMMUNICATION_REQUEST_LIST, API_DEAL_APPROVE_REJECT, API_DEAL_REQUEST_LIST, API_DELETE_CREDIT, API_EDIT_CREDIT, API_GET_CREDIT_DETAIL, API_GET_CREDIT_LIST, API_EDITPROFILE_REQUEST_LIST_GUIDE, API_EDITPROFILE_REQUEST_LIST_VENUE, API_EDITPROFILE_REQUEST_APPROVE_REJECT_GUIDE , API_EDITPROFILE_REQUEST_APPROVE_REJECT_VENUE } from '../constants/index'


const initialState = {
    isLoading: false,
    isLoggedIn: false,
    creditDetail: {},
    sidebarShow: true,
    creditList: { data: [], recordsTotal: 0 },
    dealRequestList: { data: [], recordsTotal: 0 },
    communicationRequestList: { data: [], recordsTotal: 0 },

}

//Get Credit List Action
export const getCreditList = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_CREDIT_LIST,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getCreditList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getCreditList/fail',
            payload: {},
        }),
    },
})

export const addCredit = (data) => ({
    type: 'API',
    payload: {
        url: API_ADD_CREDIT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'addCredit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'addCredit/fail',
            payload: {},
        }),
    },
})
//Get credit Detail Action
export const getCreditDetail = (data) => ({
    type: 'API',
    payload: {
        url: API_GET_CREDIT_DETAIL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getCreditDetail/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getCreditDetail/fail',
            payload: {},
        }),
    },
})

//credit Edit Action
export const creditEditAction = (data) => ({
    type: 'API',
    payload: {
        url: API_EDIT_CREDIT,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'creditEdit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'creditEdit/fail',
            payload: {},
        }),
    },
})

//User Delete Action
export const creditDeleteAction = (data) => ({
    type: 'API',
    payload: {
        url: API_DELETE_CREDIT,
        method: 'DELETE',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'deleteCredit/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'deleteCredit/fail',
            payload: {},
        }),
    }
})

//approve/reject deal 
export const approveRejectDeal = (data) => ({
    type: 'API',
    payload: {
        url: API_DEAL_APPROVE_REJECT,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRejectDeal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRejectDeal/fail',
            payload: {},
        }),
    },
})

//approve reject deal request list
export const getDealRequestList = (data) => ({
    type: 'API',
    payload: {
        url: API_DEAL_REQUEST_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getdealRequestList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getdealRequestList/fail',
            payload: {},
        }),
    },
})
//approve reject edit profile request list for Guild 
export const getguildeditProfileRequestList = (data) => ({
    type: 'API',
    payload: {
        url: API_EDITPROFILE_REQUEST_LIST_GUIDE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getguildeditProfileRequestList/success',
            payload: data.data,
        }),
        error: (data) => ({
            type: 'getguildeditProfileRequestList/fail',
            payload: {},
        }),
    },
})
//approve/reject Edit Profile Request for Guide
export const approveRejectEditProfileRequest_guide = (data) => ({
    type: 'API',
    payload: {
        url: API_EDITPROFILE_REQUEST_APPROVE_REJECT_GUIDE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRejectEditProfileRequest_guide/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRejectEditProfileRequest_guide/fail',
            payload: {},
        }),
    },
})
//approve/reject Edit Profile Request for venue
export const approveRejectEditProfileRequest_venue = (data) => ({
    type: 'API',
    payload: {
        url: API_EDITPROFILE_REQUEST_APPROVE_REJECT_VENUE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRejectEditProfileRequest_venue/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRejectEditProfileRequest_venue/fail',
            payload: {},
        }),
    },
})

//approve reject edit profile request list for Guild 
export const getvenueeditProfileRequestList = (data) => ({
    type: 'API',
    payload: {
        url: API_EDITPROFILE_REQUEST_LIST_VENUE,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getvenueeditProfileRequestList/success',
            payload: data.data,
        }),
        error: (data) => ({
            type: 'getvenueeditProfileRequestList/fail',
            payload: {},
        }),
    },
})

//approve/reject communication 
export const approveRejectCommunication = (data) => ({
    type: 'API',
    payload: {
        url: API_COMMUNICATION_APPROVE_REJECT,
        method: 'PATCH',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'approveRejectCommunication/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'approveRejectCommunication/fail',
            payload: {},
        }),
    },
})

//approve reject communication request list
export const getCommunicationRequestList = (data) => ({
    type: 'API',
    payload: {
        url: API_COMMUNICATION_REQUEST_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getCommunicationRequestList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getCommunicationRequestList/fail',
            payload: {},
        }),
    },
})

// Reducer
const getCreditSlice = createSlice({
    name: 'CreditSclice',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getCreditList/success', (state, action) => {
            state.creditList = action.payload
        })
        builder.addCase('getCreditList/fail', (state, action) => {
            state.creditList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('addCredit/success', (state, action) => {
            state.creditList = action.payload.data
        })
        builder.addCase('addCredit/fail', (state, action) => {
            state.creditList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getCreditDetail/success', (state, action) => {
            state.creditDetail = action.payload.data
        })
        builder.addCase('getCreditDetail/fail', (state, action) => {
            state.creditDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('creditEdit/success', (state, action) => {
            state.creditDetail = action.payload.data
        })
        builder.addCase('creditEdit/fail', (state, action) => {
            state.creditDetail = {}
            state.isLoggedIn = false
        })
        builder.addCase('getdealRequestList/success', (state, action) => {
            state.dealRequestList = action?.payload?.data
        })
        builder.addCase('getdealRequestList/fail', (state, action) => {
            state.dealRequestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getguildeditProfileRequestList/success', (state, action) => {
            state.guildRequestList = action?.payload
        })
        builder.addCase('getguildeditProfileRequestList/fail', (state, action) => {
            state.guildRequestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getvenueeditProfileRequestList/success', (state, action) => {
            state.venueRequestList = action?.payload
        })
        builder.addCase('getvenueeditProfileRequestList/fail', (state, action) => {
            state.venueRequestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getCommunicationRequestList/success', (state, action) => {
            state.communicationRequestList = action?.payload?.data
        })
        builder.addCase('getCommunicationRequestList/fail', (state, action) => {
            state.communicationRequestList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })

    },
})

export const { loaderChange } = getCreditSlice.actions
export default getCreditSlice.reducer
