// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;

// Place map key
export const { REACT_APP_PLACE_API_KEY: PLACE_KEY } = process.env;

//Firebase
export const { REACT_APP_FIREBASE_KEY: FIREBASE_KEY } = process.env;
export const { REACT_APP_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN } = process.env;
export const { REACT_APP_PROJECTID: FIREBASE_PROJECTID } = process.env;
export const { REACT_APP_STORAGE_BUCKET: FIREBASE_STORAGE_BUCKET } = process.env;
export const { REACT_APP_MESSAGE_SENDER_ID: FIREBASE_SENDER_ID } = process.env;
export const { REACT_APP_APP_ID: FIREBASE_APP_ID } = process.env;
export const { REACT_APP_MEASUREMENT_ID: FIREBASE_MEASUREMENT_ID } = process.env;
export const { REACT_APP_VAPID_KEY: FIREBASE_VAPID_KEY } = process.env;

// LOCALSTORAGE KEYNAME
export const LS_USER = `user${APP_NAME}`
export const LS_AUTHTOKEN = `authToken${APP_NAME}`

// TYPES FOR REDUCER
export const LOGIN_S = `LOGIN_S`;
export const LOGIN_F = `LOGIN_F`;


// API ENDPOINTS
//Login 
export const API_LOGIN = `${API_BASE}/admin/login`;
export const API_CHANGE_PASSWORD = `${API_BASE}/admin/auth/changePassword`;
export const API_SIGN_OUT = `${API_BASE}/admin/auth/signOut`;

//User management
export const API_GET_USER_LIST = `${API_BASE}/admin/auth/allUsers`;
export const API_ADD_USER = `${API_BASE}/admin/auth/addUser`;
export const API_USER_DELETE = `${API_BASE}/admin/auth/deleteUser`;
export const API_GET_USER_DETAIL = `${API_BASE}/admin/auth/user`;
export const API_USER_EDIT = `${API_BASE}/admin/auth/updateUser`
export const API_ACTIVE_INACTIVE = `${API_BASE}/admin/auth/activeInactiveUser`

export const API_USER_ANALYTICS = `${API_BASE}/admin/auth/userAnalytics`;
export const API_USER_GOAT_ANALYTICS = `${API_BASE}/admin/auth/userGoatAnalytics`;
export const API_DELETE_GOAT = `${API_BASE}/admin/auth/deleteGoat`;

export const API_INVITE_GUIDE = `${API_BASE}/admin/auth/inviteGuide`;

//CMS
export const API_GET_CMS_DETAIL = (type) => `${API_BASE}/getCms?type=${type}`;
export const API_UPDATE_CMS = `${API_BASE}/admin/auth/updateCms`

//onboarding screen
export const API_GET_ONBOARDINGSCREENS_LIST = `${API_BASE}/getOnboardingScreens`;
export const API_ADD_ONBOARDINGSCREENS = `${API_BASE}/admin/auth/createOnboarding`;
export const API_DELETE_ONBOARDINGSCREENS = `${API_BASE}/admin/auth/deleteOnboarding`;
export const API_EDIT_ONBOARDINGSCREENS = `${API_BASE}/admin/auth/updateOnboarding`;
export const API_GET_ONBOARDINGSCREENS_DETAIL = `${API_BASE}/admin/auth/getOnboardingDetails`;


// venue Profile Management 

export const API_ADD_VENUEOWNER = `${API_BASE}/admin/auth/addVenueOwner`;
export const API_ALL_VENUEOWNER = `${API_BASE}/admin/auth/allVenueOwnerOptimised`;
export const API_UPDATE_VENUEOWNER = `${API_BASE}/admin/auth/updateVenueOwner`;
export const API_DELETE_VENUEOWNER = `${API_BASE}/admin/auth/deleteVenueOwner`;
export const API_GET_VENUE_ANALYTICS = `${API_BASE}/admin/auth/getVenueAnalytics`;
export const UPDATE_GOAT_LEVEL = `${API_BASE}/admin/auth/updateIndividualGoatLevel`;
export const API_GET_REPORTED_USER_LIST = `${API_BASE}/admin/auth/restaurantReportDetails`;
export const API_ACTIVE_INACTIVE_OWNER = `${API_BASE}/admin/auth/changeMenuPermission`
export const API_GOAT_HISTORY = `${API_BASE}/admin/auth/getRestaurantGoats`
export const API_ADD_GLOBAL_CREDITS = `${API_BASE}/admin/auth/addCredits`


// Cuisine Management

export const API_ADD_CUISINE = `${API_BASE}/admin/auth/addCuisine`;
export const API_UPDATE_CUISINE = `${API_BASE}/admin/auth/updateCuisine`;
export const API_GET_CUISINE = `${API_BASE}/admin/auth/getCuisine`;
export const API_LIST_CUISINE = `${API_BASE}/admin/auth/listCuisine`;
export const API_DELETE_CUISINE = `${API_BASE}/admin/auth/deleteCuisine`;
export const API_CHANGE_SEQUENCE_MEAL = `${API_BASE}/admin/auth/changeSequenceMeal`;


// item Management

export const API_ADD_FOODITEM = `${API_BASE}/admin/auth/addFoodItem`;
export const API_UPDATE_FOODITEM = `${API_BASE}/admin/auth/updateFoodItem`;
export const API_GET_FOODITEM = `${API_BASE}/admin/auth/getFoodItem`;
export const API_DELETE_FOODITEM = `${API_BASE}/admin/auth/deleteFoodItem`;
export const API_LIST_FOODITEM = `${API_BASE}/admin/auth/listFoodItems`;


// meal Management

export const API_ADD_MEAL = `${API_BASE}/admin/auth/addMeal`;
export const API_UPDATE_MEAL = `${API_BASE}/admin/auth/updateMeal`;
export const API_LIST_MEAL = `${API_BASE}/admin/auth/listMeal`;
export const API_GET_MEAL = `${API_BASE}/admin/auth/getMeal`;
export const API_DELETE_MEAL = `${API_BASE}/admin/auth/deleteMeal`;


// heat  Management

export const API_ADD_HEATINDEX = `${API_BASE}/admin/auth/addHeatIndex`;
export const API_UPDATE_HEAT = `${API_BASE}/admin/auth/updateHeatIndex`;
export const API_LIST_HEAT = `${API_BASE}/admin/auth/fetchallHeatIndex`;
export const API_GET_HEAT = `${API_BASE}/admin/auth/getHeatIndex`;
export const API_DELETE_HEAT = `${API_BASE}/admin/auth/deleteHeatIndex`;

//SubscriptionHistory
export const GET_SUBSCRIPTION_HISTORY = `${API_BASE}/admin/auth/getSubscriptionHistory`;
export const GET_USER_GOATS = `${API_BASE}/admin/auth/getUserGoats`;

//Restaurant Request List
export const API_RESTAURANT_REQUEST_LIST = `${API_BASE}/admin/auth/restaurantRequestList`;
export const API_APPROVE_RESTAURANT = `${API_BASE}/admin/auth/approveRestaurants`;
export const GET_APPROVE_RESTAURANT_DETAIL = `${API_BASE}/admin/auth/restaurantRequestById`;
export const API_RESOLVED_REPORTED_RESTAURANT = `${API_BASE}/admin/auth/resolvedReportedRestaurant`;

// restaurant management 
export const API_LIST_RESTAURANTS = `${API_BASE}/admin/auth/listRestaurantsOptimised`;

// export const API_LIST_RESTAURANTS = `${API_BASE}/admin/auth/listRestaurants`;
export const API_UPDATE_RESTAURANTS = `${API_BASE}/admin/auth/updateRestaurant`;
export const API_DELETE_RESTAURANT = `${API_BASE}/admin/auth/deleteRestaurant`;
export const API_REPORTED_RESTAURANT = `${API_BASE}/admin/auth/getReportedRestaurant`;
export const API_REPORTED_RESTAURANT_ACTIVE = `${API_BASE}/admin/auth/activeInactiveRestaurant`
export const API_GET_ALL_FRANCHISE_LIST = `${API_BASE}/admin/auth/getAllVenueFranchise`

export const API_CHANGE_ADS_STATUS = `${API_BASE}/admin/auth/changeAdsStatus`;
export const API_CHACK_ADS_STATUS = `${API_BASE}/admin/auth/checkAdStatus`;

// admin side restaurant management 
export const API_ADD_ADMIN_RESTAURANT = `${API_BASE}/admin/auth/addRestaurant`;
export const API_LIST_ADMIN_RESTAURANT = `${API_BASE}/admin/auth/listRestaurantsOptimised`;

// export const API_LIST_ADMIN_RESTAURANT = `${API_BASE}/admin/auth/listRestaurants`;
export const API_DELETE_ADMIN_RESTAURANT = `${API_BASE}/admin/auth/deleteRestaurant`;
export const GET_DETAIL_ADMIN_RESTAURANT = `${API_BASE}/admin/auth/getRestaurantDetails`
export const GET_VENUE_OWNER = `${API_BASE}/admin/auth/getVenueOwner`
export const API_FRANCHISE_TOGGLE = `${API_BASE}/admin/auth/updateFranchiseToggle`

//payment management
export const API_USER_SUBSCRIPTION_LIST = `${API_BASE}/admin/auth/getUserSubscriptionList`;
export const API_PAYMENT_ANALYTICS = `${API_BASE}/admin/auth/getPaymentAnalysis`;

//advertise management - define in payment management slice
export const API_ADVERTISE_LIST = `${API_BASE}/admin/auth/getAdvertisesByRestaurant`;



// goat guide management 
export const API_GUIDE_ALL_USERS = `/admin/auth/allUsers`;
export const API_GUIDE_DELETE_USER = `/admin/auth/deleteGuide`;
export const API_GUIDE_GET_USER = `/admin/auth/user`;
export const API_GUIDE_UPDATE_USER = `/admin/auth/updateUser`;
export const API_GUIDE_ANALYTICS = `/admin/auth/guideAnalytics`;
export const API_getUserBycity = `/admin/auth/getUserByCity`;
export const API_getUserBystate = `/admin/auth/getUserByState`;
export const UPDATE_UNIVERSAL_GOAT_LEVEL = `${API_BASE}/admin/auth/updateUniversalGoatLevel`;
export const GET_UNIVERSAL_GOAT_LEVEL = `${API_BASE}/admin/auth/getUniversalGoatLevel`

//notification management
export const API_GET_NOTIFICATION_LIST = `${API_BASE}/admin/auth/listScheduleNotification`;
export const API_ADD_NOTIFICATION = `${API_BASE}/admin/auth/scheduleNotification`;
export const API_NOTIFICATION_DELETE = `${API_BASE}/admin/auth/deleteScheduleNotification`;
export const API_GET_NOTIFICATION_DETAIL = `${API_BASE}/admin/auth/getScheduleNotification`;
export const API_NOTIFICATION_EDIT = `${API_BASE}/admin/auth/updateScheduleNotification`;
export const API_NOTIFICATION_ANALYTICS = `${API_BASE}/admin/auth/notificationAnalytics`
export const API_SENT_NOTIFICATION = `${API_BASE}/admin/auth/listSentNotification`;

//Easter Egg Link Management
export const API_GET_LIST_EASTER_LINK = `${API_BASE}/admin/auth/getEasterEggLink`;
export const API_ADD_EASTER_LINK = `${API_BASE}/admin/auth/createEasterEggLink`;
export const API_UPDATE_EASTER_LINK = `${API_BASE}/admin/auth/updateEasterEggLink`;
export const API_DELETE_EASTER_LINK = `${API_BASE}/admin/auth/deleteEasterEggLink`;

//tutorial management
export const API_GET_LIST_TUTORIAL_LINK = `${API_BASE}/admin/auth/getTutorialLink`;
export const API_ADD_TUTORIAL_LINK = `${API_BASE}/admin/auth/createTutorialLink`;
export const API_UPDATE_TUTORIAL_LINK = `${API_BASE}/admin/auth/updateTutorialLink`;
export const API_DELETE_TUTORIAL_LINK = `${API_BASE}/admin/auth/deleteTutorialLink`;

//Credit management
export const API_GET_CREDIT_LIST = `${API_BASE}/admin/auth/creditPlans/getAll`;
export const API_ADD_CREDIT = `${API_BASE}/admin/auth/creditPlans/addUpdate`;
export const API_EDIT_CREDIT = `${API_BASE}/admin/auth/creditPlans/addUpdate`;
export const API_DELETE_CREDIT = `${API_BASE}/admin/auth/creditPlans/delete`;
export const API_GET_CREDIT_DETAIL = `${API_BASE}/admin/auth/creditPlans/getCreditPlan`;

export const API_DEAL_REQUEST_LIST = `${API_BASE}/admin/auth/listDeal`;
export const API_DEAL_APPROVE_REJECT = `${API_BASE}/admin/auth/changeDealStatus`;


export const API_COMMUNICATION_REQUEST_LIST = `${API_BASE}/admin/auth/commmunication/list`;
export const API_COMMUNICATION_APPROVE_REJECT = `${API_BASE}/admin/auth/commmunication/changeStatus`;

// anaylytic module.
export const API_GET_USERS_BY_AGE = `${API_BASE}/admin/auth/getUsersByAge`
export const API_getUsersByState = `${API_BASE}/admin/auth/getUsersByState`
export const API_getUsersByCity = `${API_BASE}/admin/auth/getUsersByCity`
export const API_getUserViewSAnalytics = `${API_BASE}/admin/auth/getUserViewsAnalytics`
export const API_Goat_Average = `/admin/auth/getAnalyticsGoatAverage`
export const API_LOWEST_HIGHEST_SEARCH_ANALYTICS = `${API_BASE}/admin/auth/highestLowestSearchAnalytics`;
export const API_GOAT_GIVEN_LOST_ANALYTICS = `${API_BASE}/admin/auth/goatGivenLostAnalytics`

// anylytic types
export const GET_USERS_BY_AGE = `GET_USERS_BY_AGE`
export const getUsersByState = `getUsersByState`
export const getUsersByCity = `getUsersByCity`
export const goatAverage = `goatAverage`
export const getUserViewSAnalytics = `getUserViewSAnalytics`
export const getLowestHighestSearchHits = `getLowestHighestSearchHits`;
export const getGoatGivenLost = `getGoatGivenLost`;

//subitems 
export const API_LIST_SUBITEMS = `${API_BASE}/admin/auth/listFoodSubItems`
export const API_LIST_ADD_SUBITEMS = `${API_BASE}/admin/auth/addSubItem`
export const API_UPDATE_SUBITEMS = `${API_BASE}/admin/auth/updateSubItem`
export const API_DELETE_SUBITEMS = `${API_BASE}/admin/auth/deleteSubItem`
export const API_GET_SUBITEMS = `${API_BASE}/admin/auth/getSubItem`
export const API_LIST_CUISINE_SUBITEMS = `${API_BASE}/admin/auth/listCuisineForSubItems`
export const API_LIST_MEAL_SUBITEMS = `${API_BASE}/admin/auth/listMealsForSubItems`
export const API_LIST_FOODITEM_SUBITEMS = `${API_BASE}/admin/auth/listFoodItemsForSubItems`

//Franchise management
export const API_ADD_FRANCHISE = `${API_BASE}/admin/auth/addFranchise`;
export const API_UPDATE_FRANCHISE = `${API_BASE}/admin/auth/updateFranchise`;
export const API_GET_FRANCHISE = `${API_BASE}/admin/auth/getFranchise`;
export const API_DELETE_FRANCHISE = `${API_BASE}/admin/auth/deleteFranchise`;
export const API_LIST_FRANCHISE = `${API_BASE}/admin/auth/getAllFranchise`;

//Edit Profile Requests
export const API_EDITPROFILE_REQUEST_LIST_GUIDE = `${API_BASE}/admin/auth/getGuideEditReqList`;
export const API_EDITPROFILE_REQUEST_LIST_VENUE = `${API_BASE}/admin/auth/getAllRestaurantEditReqList`;
export const API_EDITPROFILE_REQUEST_APPROVE_REJECT_GUIDE = `${API_BASE}/admin/guideEditApprovedRejected`;
export const API_EDITPROFILE_REQUEST_APPROVE_REJECT_VENUE = `${API_BASE}/admin/auth/restaurantEditApprovedRejected`;