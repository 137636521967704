import { Button, Col, Form, Radio, Row, Input, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../Login/loginCss";
import {
  LocationSearchInput,
  RenderAutoComplete,
  RenderInput,
} from "../../utils/FormField";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UPDATERestaurant } from "../../Redux/VenueProfileSlice";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import { UploadIcon } from "../../svg";
import { getAllFranchiseList } from "../../Redux/RestaurantManagementSlice";
// import { RightIconWhite, UploadIcon } from '..';

const EditRestaurant = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    _id,
    name,
    restaurantFranchise,
    phoneNumber,
    email,
    address,
    websiteLink,
    openTableLink,
    venueId,
    isRestaurantFranchise,
    restaurantLogo,
    coverImage: cover_image,
    lat,
    lng
  } = location.state.record || {};
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const [restaurantImage, setrestaurantImage] = useState([]);
  const handleCoverImage = ({ file, fileList }) => setcoverImage(fileList);
  const handleImage = ({ file, fileList }) => {
    setrestaurantImage(fileList);
  };
  const [coverImage, setcoverImage] = useState([]);
  // const [lat, setLat] = useState("");
  // const [lng, setLng] = useState("");
  // const [city, setCity] = useState();
  // const [state, setState] = useState();
  // const [venueName, setVenueName] = useState();
  // const [formattedAddress, setFormattedAddress] = useState();
  // const [website, setWebsite] = useState();
  // const [placeId, setPlaceId] = useState();
  const [inputValue, setInputValue] = useState("");
  const [allFranchiseList, setAllFranchise] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (values) => {
    setIsSubmitting(true)
    let franchiseId = allFranchiseList?.find((item) => {
      return item?.value === inputValue;
    });

    let formData = new FormData();
    formData.append("isClaimed", false);
    formData.append("restaurantId", _id);
    // if (placeId !== undefined && placeId !== "") {
    //   formData.append("placeId", placeId);
    // }
    formData.append("name", values.name);
    if (values.email !== undefined && values.email !== "") {
      formData.append("email", values?.email);
    }

    if (franchiseId?.id) {
      formData.append("franchise", franchiseId?.id);
    } else if (inputValue) {
      formData.append("franchise", inputValue);
    } else {
      formData.append("franchise", restaurantFranchise?.id);
    }

    formData.append("isRestaurantFranchise", values?.isRestaurantFranchise);

    formData.append("lat", values.lat);
    formData.append("lng", values.lng);
    if (values.address !== undefined && values.address !== "") {
      formData.append("shortAddress", values.address);
    }
    if (values.websiteLink !== undefined && values.websiteLink !== "") {
      formData.append("websiteLink", values.websiteLink);
    }


    if (values.openTableLink !== undefined && values.openTableLink !== "") {
      formData.append("openTableLink", values.openTableLink);
    }
    formData.append("phoneNumber", values.phoneNumber);
    if (restaurantImage.length > 0) {
      formData.append("isResturantImageRemove", false);
      if (restaurantImage[0].hasOwnProperty("originFileObj")) {
        formData.append("restaurantLogo", restaurantImage[0].originFileObj);
      }
    } else {
      formData.append("isResturantImageRemove", true);
    }
    // else {
    //     setrestaurantImageError(true)
    //     toast.error("Restaurant Logo is required.")
    //     return
    // }
    if (coverImage.length > 0) {
      formData.append("isCoverImageRemove", false);
      if (coverImage[0].hasOwnProperty("originFileObj")) {
        formData.append("coverImage", coverImage[0].originFileObj);
      }
    } else {
      formData.append("isCoverImageRemove", true);
    }
    dispatch(UPDATERestaurant(formData))
      .then((response) => {
        setIsSubmitting(false)
        navigate(-1, {
          state: {
            venue: { _id: venueId },
          },
        });
        toast.success(response.message);
      })
      .catch((error) => {
        toast.error(error.message)
        setIsSubmitting(false)
      });
  };

  // let useCount = allFranchiseList?.find((item) => {
  //   return item.value === inputValue;
  // });

  useEffect(() => {
    setValue(isRestaurantFranchise);
    form.setFieldsValue({
      name,
      phoneNumber,
      address: address,
      websiteLink: websiteLink === undefined ? "" : websiteLink,
      isRestaurantFranchise: isRestaurantFranchise,
      openTableLink: openTableLink === undefined ? "" : openTableLink,
      email: email,
      franchise: restaurantFranchise?.name,
      lat: lat,
      lng: lng
    });

    if (restaurantLogo !== undefined && restaurantLogo !== "") {
      setrestaurantImage([
        {
          _id: _id,
          url: restaurantLogo,
        },
      ]);
    }
    if (cover_image !== undefined && cover_image !== "") {
      setcoverImage([
        {
          _id: `${_id}A`,
          url: cover_image,
        },
      ]);
    }

  }, [_id, address, cover_image, email, form, isRestaurantFranchise, lat, lng, location.state.record, name, openTableLink, phoneNumber, restaurantFranchise?.name, restaurantLogo, websiteLink]);

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const handleCancel = () => {
    navigate(-1, {
      state: {
        venue: { _id: venueId },
      },
    });
  };
  const handleDeleteCoverImage = () => setcoverImage([]);
  const handleDelete = () => setrestaurantImage([]);
  const uploadButton = (
    <div>
      <UploadIcon />
    </div>
  );

  const validateUrl = (rule, value, callback) => {
    // Basic URL pattern validation using a regular expression
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    if (value && !urlPattern.test(value)) {
      callback("Please enter a valid URL");
    } else {
      callback();
    }
  };

  useEffect(() => {
    dispatch(getAllFranchiseList())
      .then((response) => {
        const uniqueOptionsMap = new Map();
        response.data.forEach(item => {
          if (!uniqueOptionsMap.has(item.name)) {
            uniqueOptionsMap.set(item.name, { value: item.name, label: item.name, id: item._id });
          }
        });
        const uniqueOptions = Array.from(uniqueOptionsMap.values());
        setAllFranchise(uniqueOptions);
      })
      .catch((error) => toast.error(error.message));
  }, [dispatch]);

  const handleSearch = (searchText) => {
    setInputValue(searchText);
  };

  return (
    <Wrapper>
      <div className="shadow-paper">
        {/* <Button type="default" htmlType="submit" onClick={() => navigate('/venue-info')}></Button> */}
        <div
          className="page-header page-sub-header"
          style={{ marginBottom: "35px" }}
        >
          <h2>Venue Information</h2>
        </div>
        <div></div>
        <div className="shadow-form">
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={[50, 0]}>
              <Col lg={12} xs={24}>
                <Row gutter={[0, 36]}>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      name="isRestaurantFranchise"
                      label="Is this restaurant a franchise?"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Franchise field is required.",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={onChange}
                        defaultValue={isRestaurantFranchise}
                        value={value}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {value === true ? (
                    <>
                      {" "}
                      <RenderAutoComplete
                        col={{ lg: 24, xs: 24 }}
                        name="franchise"
                        placeholder="Franchise Name"
                        label="Franchise Name"
                        rules={[
                          {
                            required: true,
                            message: "Franchise name field is required.",
                          },
                        ]}
                        // disabled={true}
                        value={inputValue}
                        options={allFranchiseList}
                        onSelect={(selectedValue) => {
                          // Do something with the selected value
                          setInputValue(selectedValue);
                        }}
                        onSearch={handleSearch}
                      />
                      {/* <Form.Item
                        label="Franchise Count"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <div
                          className="use-counter"
                          style={{ textAlign: "center" }}
                        >
                          <span>
                            {useCount?.count?.toString() ||
                              restaurantFranchise?.useCount?.toString()}
                          </span>
                        </div>
                      </Form.Item> */}
                    </>
                  ) : null}

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="name"
                    placeholder="Venue Name"
                    label="Venue Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Address.",
                      },
                    ]}
                  />
                  {/* <LocationSearchInput
                    setState={setState}
                    setCity={setCity}
                    venueName={venueName}
                    setLat={setLat}
                    setLng={setLng}
                    setPlaceId={setPlaceId}
                    setVenueName={setVenueName}
                    setFormattedAddress={setFormattedAddress}
                    setWebsite={setWebsite}
                    form={form}
                    col={{ lg: 24, xs: 24 }}
                    name="name"
                    placeholder="Venue Name"
                    label="Venue Name"
                    disabled
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Name.",
                      },
                    ]}
                  /> */}

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="email"
                    placeholder="Email"
                    label="Email"
                    // disabled
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter your valid E-mail.",
                      },
                    ]}
                  />

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="address"
                    placeholder="Venue Address"
                    label="Venue Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Venue Address.",
                      },
                    ]}
                  />
                  <Col lg={24} xs={24}>

                    <Row gutter={[12, 36]}>

                      <RenderInput
                        col={{ lg: 12, xs: 12 }}
                        name="lat"
                        placeholder="Enter your Latitude"
                        label="Latitude"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Latitude.",
                          },
                          {
                            validator: (_, value) => {
                              if (!value || isNaN(value)) {
                                return Promise.reject("Latitude must be a number.");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      />

                      <RenderInput
                        col={{ lg: 12, xs: 12 }}
                        name="lng"
                        placeholder="Enter your Longitude"
                        label="Longitude"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Longitude.",
                          },
                          {
                            validator: (_, value) => {
                              if (!value || isNaN(value)) {
                                return Promise.reject("Longitude must be a number.");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      />
                    </Row>
                  </Col>
                  {/* <LocationSearchInput

                                        // setCountry={setCountry}
                                        setState={setState}
                                        setCity={setCity}
                                        setZipCode={setZipCode}
                                        setLat={setLat}
                                        setLng={setLng}
                                        locationAddress={locationAddress}
                                        setLocationAddress={setLocationAddress}
                                        form={form}
                                        col={{ lg: 24, xs: 24 }}
                                        name="address"
                                        placeholder="Venue Address 123"
                                        label="Venue Address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your Venue Address.',
                                            },
                                        ]}
                                    /> */}
                  {/* <Col lg={24} xs={24} >
                                        <Form.Item
                                            name="isRestaurantChain"
                                            label="Is this restaurant a chain?"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'chain field is required',
                                                },
                                            ]}
                                        >
                                            <Radio.Group onChange={onChange} defaultValue={isRestaurantChain} value={value}>
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col> */}
                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="websiteLink"
                    placeholder="Venue website hyperlink"
                    label="Venue Website Hyperlink"
                    type="text"
                    rules={[{ validator: validateUrl }]}
                  />

                  <RenderInput
                    col={{ lg: 24, xs: 24 }}
                    name="openTableLink"
                    placeholder="Venue Reservation Link (OpenTable, Resy, Reserve with Google, etc.)"
                    label="Reservation Link"
                    type="text"
                    rules={[{ validator: validateUrl }]}
                  />
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row gutter={[0, 36]}>
                  {/* <RenderInput
                                        col={{ lg: 24, xs: 24 }}
                                        name="phoneNumber"
                                        label="Venue Phone Number"
                                        type="number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'phone number is required field',
                                            },
                                        ]}
                                    /> */}
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Venue Phone Number"
                    placeholder="Venue phone number"
                    name="phoneNumber"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value) {
                            const phoneNumber = value.replace(/\D/g, '');
                            if (phoneNumber.length !== 11) {
                              const error = new Error('Please enter valid phone number.');
                              return Promise.reject(error);
                            }
                          } else {
                            const error = new Error('Please enter phone number.');
                            return Promise.reject(error);
                          }
                        }
                      }
                    ]}
                  >
                    <PatternFormat
                      name="phoneNumber"
                      placeholder="Venue phone number"
                      // addonBefore={prefixSelector}
                      format="+1 (###) ###-####"
                      customInput={Input}
                    />
                  </Form.Item>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      label="Add Restaurant Logo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="restaurantLogo"
                    >
                      <Upload
                        listType="picture-card"
                        fileList={restaurantImage || []}
                        maxCount={1}
                        multiple={false}
                        onChange={handleImage}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {restaurantImage.length > 0 ? null : uploadButton}
                      </Upload>
                      {restaurantImage.length <= 0 ? null :
                        <Button onClick={handleDelete}>Delete</Button>
                      }
                    </Form.Item>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      label="Cover Image"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="coverImage"
                    >
                      <Upload
                        listType="picture-card"
                        fileList={coverImage}
                        maxCount={1}
                        onChange={handleCoverImage}
                        multiple={false}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {coverImage.length > 0 ? null : uploadButton}
                      </Upload>
                      {coverImage.length <= 0 ? null :
                        <Button onClick={handleDeleteCoverImage}>Delete</Button>
                      }
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <div className="btn-group d-flex align-items-center justify-content-end">
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Wrapper>
  );
};

export default EditRestaurant;
