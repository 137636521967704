import { createSlice } from '@reduxjs/toolkit'
import { API_ADVERTISE_LIST, API_PAYMENT_ANALYTICS, API_USER_SUBSCRIPTION_LIST, GET_UNIVERSAL_GOAT_LEVEL, UPDATE_UNIVERSAL_GOAT_LEVEL } from '../constants/index'


// const projectName = "GOAT-Grub";

const initialState = {
    isLoading: false,
    isLoggedIn: false,
    sidebarShow: true,
    userSubscriptionList: { data: [], recordsTotal: 0 },
    advertiseList: { data: [], recordsTotal: 0 },
    goatUpdateUniversal: {},
    goatUniversalDetail: {},
    paymentAnalytics: {},
    page: 0,
    limit: 10,
}

//Get User subscription List Action
export const getUserSubscriptionList = (data) => ({
    type: 'API',
    payload: {
        url: API_USER_SUBSCRIPTION_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUserSubscriptionList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getSubscriptionList/fail',
            payload: {},
        }),
    },
})

//advertise management list
export const getAdvertiseList = (data) => ({
    type: 'API',
    payload: {
        url: API_ADVERTISE_LIST,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getAdvertiseList/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getAdvertiseList/fail',
            payload: {},
        }),
    },
})

export const updateGoatUniversal = (data) => ({
    type: 'API',
    payload: {
        url: UPDATE_UNIVERSAL_GOAT_LEVEL,
        method: 'POST',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'updateGoatUniversal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'updateGoatUniversal/fail',
            payload: {},
        }),
    },
})
export const getUpdateGoatUniversal = (data) => ({
    type: 'API',
    payload: {
        url: GET_UNIVERSAL_GOAT_LEVEL,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'getUpdateGoatUniversal/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'getUpdateGoatUniversal/fail',
            payload: {},
        }),
    },
})


// user analytics
export const getPaymentAnalytics = (data) => ({
    type: 'API',
    payload: {
        url: API_PAYMENT_ANALYTICS,
        method: 'GET',
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: 'paymentAnalytics/success',
            payload: data,
        }),
        error: (data) => ({
            type: 'paymentAnalytics/fail',
            payload: {},
        }),
    },
})
// Reducer
const getPaymentSlice = createSlice({
    name: 'getUserSubscriptionList',
    initialState: initialState,
    reducers: {
        loaderChange: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase('set', (state, action) => {
            state.sidebarShow = action.payload
        })
        builder.addCase('getUserSubscriptionList/success', (state, action) => {
            state.userSubscriptionList = action.payload.data
        })
        builder.addCase('getUserSubscriptionList/fail', (state, action) => {
            state.userSubscriptionList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('getAdvertiseList/success', (state, action) => {
            state.advertiseList = action.payload.data
        })
        builder.addCase('getAdvertiseList/fail', (state, action) => {
            state.advertiseList = { data: [], recordsTotal: 0 }
            state.isLoggedIn = false
        })
        builder.addCase('updateGoatUniversal/success', (state, action) => {
            // console.log('action: ', action);
            state.goatUpdateUniversal = action.payload.data
        })
        builder.addCase('updateGoatUniversal/fail', (state, action) => {
            state.goatUpdateUniversal = {}
            state.isLoggedIn = false
        })
        builder.addCase('paymentAnalytics/success', (state, action) => {
            state.paymentAnalytics = action.payload.data
        })
        builder.addCase('paymentAnalytics/fail', (state, action) => {
            state.paymentAnalytics = {}
            state.isLoggedIn = false
        })
        builder.addCase('getUpdateGoatUniversal/success', (state, action) => {
            // console.log('action: ', action);
            state.goatUniversalDetail = action.payload.data
        })
        builder.addCase('getUpdateGoatUniversal/fail', (state, action) => {
            state.goatUniversalDetail = {}
            state.isLoggedIn = false
        })

    },
})

export const { loaderChange } = getPaymentSlice.actions
export default getPaymentSlice.reducer
